<template>
  <div class="course-page">
    <div class="wrap">
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/list' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="course-info z-flex z-flex-between">
        <div class="course-info-l">
          <img class="img" :src="img"/>
        </div>
        <div class="course-info-r z-flex z-flex-col z-flex-between">
          <div class="col-1 z-flex">
            <el-tag effect="dark" size="medium" class="type" v-if="false">{{type}}</el-tag>
            <div class="title">{{title}}</div>
            <a :class="['btn-collect z-flex',isCollect?'collected':'']" href="javascript:void(0)" @click="collect">
              <i class="el-icon-star-on"></i>
              <span>{{isCollect?'已收藏':'收藏课程'}}</span>
            </a>
          </div>
          <div class="col-2 z-flex">
            <span class="col-l">标签：</span>
            <el-button type="primary" size="mini" plain v-for="(item,index) in categoryLabels" :key="index" @click="searchLabel(item.name)" >{{item.name}}</el-button>
          </div>
          <div class="col-3 z-flex">
            <span class="col-l">课时：</span>
            <span>{{classHour}}节</span>
          </div>
          <div class="info-bottom">
            <div class="price z-flex z-flex-between">
              <div class="z-flex" style="align-items:flex-end">
                <span class="col-l">会员价格：</span>
                <div class="freePrice" v-if="priceNow=='0.00'">会员免费</div>
                <div class="price-now" v-else>￥{{priceNow}}</div>
                <div class="price-ori" v-if="priceOri">￥{{priceOri}}</div>
              </div>
              <div>
<!--                <el-button type="primary" size="medium">全课程附件下载</el-button>-->
                <el-button type="danger" size="medium" @click="study">开始学习</el-button>
              </div>
            </div>
            <div class="desc">{{desc}}</div>
          </div>
        </div>
      </div>
      <div class="main-con z-flex z-flex-between">
        <div class="main-con-l">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="课程目录" name="courseCatelog">
              <div class="catelog-list" v-if="courseCatelog.length>0">
                <div class="item z-flex" v-for="(item,index) in courseCatelog" :key="index" @click="toVideo(item)">
                  <i class="icon-play el-icon-video-play"></i>
                  <span class="title">{{item.title}}</span>
<!--                  <el-tag class="attach" effect="dark" size="mini" v-if="item.attach" @click.stop="downloadThisAttach">本节课附件下载</el-tag>-->
                  <span class="duration">{{item.duration}}</span>
                </div>
              </div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane label="课程介绍" name="courseIntro">
              <div class="courseIntro-con" v-if="courseIntro" v-html="courseIntro"></div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane label="讨论区" name="comments" v-if="false">
              <div class="comments-con" v-if="comments"></div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
            <el-tab-pane label="课程附件" name="CourseAttachments">
              <div class="catelog-list attachments-list" v-if="courseAttachments.length>0">
                <div class="item z-flex z-flex-between" v-for="(item,index) in courseAttachments" :key="index">
                  <span class="title">{{item.name}}</span>
                  <el-button class="btn-download" type="primary" @click="downloadThisAttach(item)" size="mini">下载素材</el-button>
                </div>
              </div>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 相关推荐 -->
        <recommend></recommend>
      </div>
    </div>
    <!-- 购买提示 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogBuyTips"
      width="25%"
      center
    >
      <div style="text-align:center;font-size:16px;padding:20px 0;">您还没有购买本节课程哦，购买后开通观看权限！</div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="isShowBuyVideo" @click="toBuyVideo">购买当前视频</el-button>
        <el-button v-if="isShowBuyCourse" type="primary" @click="toBuyCourse">购买本节课程</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import recommend from "../components/recommend"
export default {
  components: {
    recommend,
  },
  data() {
    return {
      title:'',//课程
      img:'',
      type:'',
      categoryLabels:[],
      classHour: 0,
      priceNow:'',
      priceOri:'',
      course_url:'',
      desc:'萨达问问',
      isBuy:false, //是否购买
      buyStatus:0, //购买状态
      isCollect:false, //收藏
      isCollectDisabled:false, //防止重复提交
      courseVideoId: 0,//当前视频id
      courseCatelog:[],//目录
      courseIntro:'',//课程介绍
      comments:'',//评论
      activeTab:'courseCatelog',
      courseAttachments:[],
      dialogBuyTips:false,
      isShowBuyVideo:false,
      isShowBuyCourse:false,
    };
  },
  computed: {
    ...mapState(['vuex_user'])
  },
  mounted() {
    this.$axios.api.courseDetail({id: this.$route.params.id}, (res) => {
      if (res.code == 0) {
        this.isCollect = res.data.isLikeCourse;
        this.isBuy = res.data.isBuy;
        this.buyStatus = res.data.buyStatus;
        this.is_free = res.data.course.is_free;
        this.title = res.data.course.title;
        this.img = res.data.course.thumb;
        this.course_url = res.data.course_url;
        this.desc = res.data.course.short_description;
        this.courseIntro = res.data.course.render_desc;
        this.priceNow = res.data.course.charge;
        this.classHour = res.data.videos.length;
        this.categoryLabels = res.data.categoryLabels;
        this.courseCatelog = res.data.videos;
        this.courseAttachments = res.data.attachs;
        window.document.title += ' - ' + this.title;
      } else {
        console.log(res.msg);
      }
    });
  },
  methods: {
    //播放
    toVideo(item){
      if (!this.vuex_user) {
        //登录窗口
        return this.$parent.$refs.navcom.login();
      }
      this.courseVideoId = item.id;
      this.$axios.api.videoDetail({id: this.courseVideoId}, (res) => {
        if (res.code == 0) {
          if (res.data.is_watch) {
            this.$router.push({
              path:'/video/'+this.courseVideoId
            })
          } else {
            this.showBuyVideo();
          }
        } else {
          console.log(res.msg);
        }
      });
    },
    //下载本节附件
    downloadThisAttach(item){
      if (!this.vuex_user) {
        //登录窗口
        return this.$parent.$refs.navcom.login();
      }
      if (this.isBuy == false && this.priceNow != '0.00') {
        //需要购买
        this.showBuyCourse();
      } else {
        let a = document.createElement("a");
        a.setAttribute("download", item.name);
        a.href = item.path;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    //开始学习
    study(){
      if (!this.vuex_user) {
        //登录窗口
        return this.$parent.$refs.navcom.login();
      }
      if (this.isBuy == false && this.priceNow != '0.00') {
        //需要购买
        this.showBuyCourse();
      } else {
        this.toVideo(this.courseCatelog[0]);
      }
    },
    //收藏课程
    collect(){
      if (this.isCollectDisabled) {
        return;
      }
      this.isCollectDisabled = true;
      this.$axios.api.courseLike(this.$route.params.id, (res) => {
        this.isCollectDisabled = true;
        if (res.code == 0) {
          this.isCollect = this.isCollect ? false : true;
        } else {
          console.log(res.msg);
        }
      });
    },
    // 显示购买课程
    showBuyCourse(){
      this.dialogBuyTips = true;
      this.isShowBuyCourse = true;
      this.isShowBuyVideo = false;
    },
    // 显示购买视频
    showBuyVideo(){
      this.dialogBuyTips = true;
      this.isShowBuyCourse = true;
      if (this.courseCatelog.length>1) {
        this.isShowBuyVideo = true;
      }
    },
    // 购买视频
    toBuyVideo(){
      this.dialogBuyTips = false;
      window.open(process.env.VUE_APP_WWW_URL + 'dist/#/pay/videos/'+this.courseVideoId);
    },
    // 购买课程
    toBuyCourse(){
      this.dialogBuyTips = false;
      window.open(process.env.VUE_APP_WWW_URL + 'dist/#/pay/courses/'+this.$route.params.id);
    },
    // 搜索标签
    searchLabel(keyword){
      window.open(process.env.VUE_APP_WWW_URL + 'dist/#/searchResult?keywords='+encodeURIComponent(keyword));
    }
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb{
  margin:20px 0;
}
.course-info{
  background-color:#fff;
  padding:20px;
  .course-info-l{
    .img{
      width:430px;
      height: 330px;
      object-fit:cover;
    }
  }
  .course-info-r{
   width: 700px;
   height:330px;
   align-items:flex-start;
    &>div{
      width:100%;
    }
    .col-l{
      color: #777;
      font-size: 14px;
    }
    .col-1{
      position: relative;
      .type{
        margin-right: 10px;
      }
      .title{
        font-size: 22px;
        font-weight: 700;
        width:500px;
        @extend %textOverflow;
      }
      .btn-collect{
        color: #707070;
        position:absolute;
        right:0;
        ::v-deep i{
          font-size: 22px;
          margin-right: 5px;
        }
        &:hover{
          color: $colorMain;
        }
        &.collected{
          color: $colorMain;
        }
      }
    }
    .info-bottom{
      width:calc(100% - 30px);
      background-color:#ecf5ff;
      padding:15px;
      color:#666;
      .desc{
        height: 66px;
        line-height:22px;
        border-top: 1px dashed #b3d8ff;
        padding-top: 10px;
        margin-top: 15px;
        font-size: 14px;
        @include textOverflowNum(3);
      }
      .price-now{
        color:red;
        font-size: 40px;
        font-weight: 700;
        margin-right: 15px;
        margin-bottom: -6px;
        span{
          font-size:20px;
          font-weight: normal;
        }
      }
      .price-ori{
        color: #999;
        text-decoration: line-through;
        font-size: 20px;
      }
    }
  }
}
.main-con{
  margin-top: 30px;
  align-items: flex-start;
  .main-con-l{
    width:895px;
    min-height: 500px;
    background-color: #fff;
    ::v-deep .el-tabs__header{
      .el-tabs__nav-wrap{
        padding:0 20px;
      }
      .el-tabs__item{
        font-size: 18px;
        height:52px;
        line-height: 52px;
      }
    }
    .catelog-list{
      padding:0 20px;
      .item{
        height: 52px;
        line-height: 52px;
        cursor: pointer;
        border-bottom: $borderDashed;
        position: relative;
        .duration{
          font-size: 14px;
          color: #999;
          position:absolute;
          right: 0;
        }
        .icon-play{
          font-size: 20px;
          margin-right: 10px;
          color:#666;
        }
        .attach{
          margin-left: 10px;
        }
        .title{
          max-width: 640px;
          @extend %textOverflow;
        }
        &:last-child{
          border: none;
        }
        &:hover{
          color:$colorMain;
          .icon-play{
            color:$colorMain;
          }
        }
      }
    }
    .courseIntro-con{
      padding:0 20px;
      ::v-deep img{
        max-width: 100%!important;
        height:auto!important;
      }
    }
    .attachments-list{
      .item{
        cursor:default;
      }
    }
  }
}

//价格免费的样式
.freePrice{
  color:red;
  font-size: 28px;
  font-weight: 700;
  margin-right: 15px;
  margin-bottom: -6px;
}

</style>
